import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';

import { AccountService } from 'app/account/account.service';
import { BaseService } from 'app/_services/base.service';
import { SettingsService } from 'app/_services/settings.service';

declare const isEmpty: any;

@Injectable({ providedIn: 'root' })
export class SellerDocumentacaoService extends BaseService {
    private _domain: string = '/seller/documentations';

    constructor(private accountService: AccountService, private config: SettingsService) {
        super();
    }

    public getAll() {
        if (isEmpty(this.usuarioLogado)) {
            this.accountService.logout();
            return EMPTY;
        }

        return this.config.executeGet({ url: this._domain });
    }

    public getDocumentation(repairerHoldingId: number) {
        return this.config.executeGet({ url: `${this._domain}/${repairerHoldingId}/documentation` })
    }

    public saveBuyersLimit(buyer_company_id: number, subscription_status: number, limit: number) {
        const body = { subscription_status, limit };
        return this.config.executePost({ url: `${this._domain}/buyers/${buyer_company_id}/save-limit`, body });
    }
}
